import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Page from "../components/page"
import Wrap from "../components/wrap"
import Lead from "../components/lead"
import Renderer from "../components/renderer"

const PageStudents = ({ data }) => {
  const students = data.students.edges.map(i => {
    return (
      <li key={i.node.id} className="student">
        <div className="student__photo">
          <div>
            <Img fluid={i.node.frontmatter.photo.childImageSharp.fluid} />
          </div>
        </div>
        <div className="student__description">
          <h3 className="student__name">
            <span>{i.node.frontmatter.name}</span>
          </h3>
          <div className="student__body">
            <Renderer>{i.node.body}</Renderer>
          </div>
        </div>
      </li>
    )
  })

  return (
    <Page title="生徒の口コミ評判">
      <Wrap>
        <Lead>
          きっかけは様々でもDJとしての夢を追い続ける1つのステップとして
          <br />
          TryHard DJ Academyで学んで来た生徒様の生の声をお届けします。
        </Lead>
        <Students>
          <ul>{students}</ul>
        </Students>
      </Wrap>
    </Page>
  )
}

export default PageStudents

export const pageQuery = graphql`
  query {
    students: allMdx(
      filter: { fields: { sourceInstanceName: { eq: "students" } } }
      sort: { fields: frontmatter___sort, order: ASC }
    ) {
      edges {
        node {
          id
          slug
          body
          frontmatter {
            name
            photo {
              childImageSharp {
                fluid(maxWidth: 450) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const Students = styled.div`
  margin: 0 auto 2rem;
  padding: 0 0 2rem;

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    padding: 0;
    list-style: none;
  }

  .student {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-column-gap: 1rem;
    background: #f1f1f1;

    &__name {
      margin: 0 0 1rem;
    }

    &__description {
      padding: 1rem 1rem 1rem 0;
    }

    &__body {
      font-size: 0.875rem;

      p {
        margin: 0 0 1rem;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  @media (max-width: 900px) {
    .student {
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;

      &__description {
        padding: 1rem;
      }
    }
  }

  @media (max-width: 500px) {
    ul {
      grid-template-columns: 1fr;
    }

    .student {
      grid-template-rows: 1fr;
      grid-template-columns: 2fr 3fr;

      &__description {
        padding: 0.5rem 0.5rem 0.5rem 0;
      }
    }
  }

  @media (max-width: 414px) {
    .student {
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;

      &__description {
        padding: 0.5rem;
      }
    }
  }
`
